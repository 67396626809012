import sharedService from '@/app/services/shared.service';
import { industries, regions } from '@/app/services/sharedData';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast, toast } from '@/app/shared/toast/toast.controller';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import clientService from '../client.service';
import CreateClient from './CreateClient.vue';



export default {
    name: "createClient",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        CreateClient,
        SideNav,
        ToastComponent


    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            regions,
            industries,
            successToastMsg: "",
            errorToastMsg: "",
            ClientModel: {
                isActive: "Y",
                thresholdValue: {
                    develop: '',
                    sufficient: '',
                    optimal: '',
                }
            },
            clientId: "",
            errors: {
                client: "",
                region: "",
                industry: "",
                email: "",
                contactPerson: "",
                develop: " ",
                sufficient:" ",
                optimal:" "
            },
            save: false,
            editClient: false,
            validateEmail: false,
            CreateClient,
            createClientResponse: {},
            showBreadcrumbs: false,
            breadcrumbStyles: {},
            developPlaceholder: "Develop",
            sufficientPlaceholder: "Sufficient",
        };
    },
    // mounted() {
    //     window.addEventListener('resize', this.handleResize);
    //     this.handleResize();
    // },

    computed: {
        developValid() {
            return this.ClientModel.thresholdValue.develop >= 0 && this.ClientModel.thresholdValue.develop <= 100;
        },
        sufficientValid() {
            return this.ClientModel.thresholdValue.sufficient > this.ClientModel.thresholdValue.develop && this.ClientModel.thresholdValue.sufficient >= 0 && this.ClientModel.thresholdValue.sufficient <= 100;
        }

    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    created() {
        this.inputReadOnly = {
            color: "#767676 !important",
        };
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))')
        const midScreenMedia = window.matchMedia('(min-width:1281px) and (max-width:1440px)');
        if (midScreenMedia.matches) {
            this.breadcrumbStyles = {
                width: "92.5%",
            }
        }
        else if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "93%",
            }
        }
        else {
            this.breadcrumbStyles = {
                width: "91.5%",
            };
        }

        //we can access the route parameters using this.$route.params object within the component.
        //if having route like /client/:clientId we can retrieve the clientId value using this.$route.params.clientId.
        this.clientId = this.$route.params.clientId;
        this.$store.commit("addToHistory", this.$route);
        if (this.clientId) {
            if (this.loggedInRole == "frameworkAdmin") {
                this.routePath = "/editClient";
            }
            this.ClientModel = sharedService.getData("client.model");
            if(this.ClientModel && (this.ClientModel.thresholdValue === null || this.ClientModel.thresholdValue === undefined)){
                this.ClientModel.thresholdValue= {
                    develop: '',
                    sufficient: '',
                    optimal: '',
                }
            }    
            if (!this.ClientModel) {
                this.fetchClient(this.clientId);
            }
            this.editClient = true;
            this.successToastMsg = " Client updated successfully";
            this.errorToastMsg = "Failed to update Client";
        } else {
            this.routePath = "/createClient";
            this.successToastMsg = " Client created successfully";
            this.errorToastMsg = "Failed to create Client";
        }
    },
    methods: {

        clientValidation() {
            if (this.ClientModel.clientName == undefined || this.ClientModel.clientName == "" || this.ClientModel.clientName == null) {
                this.errors.client = "Please Enter a Client Name";
            } else {
                this.errors.client = "";
                this.ClientModel.clientName = this.ClientModel.clientName.charAt(0).toUpperCase() + this.ClientModel.clientName.slice(1);

            }
        },
        regionValidation() {

            if (this.ClientModel.region == undefined) {
                this.errors.region = "Please Select a Region";
            } else {
                this.errors.region = "";
            }
        },
        industryValidation() {

            if (this.ClientModel.industry == undefined) {
                this.errors.industry = "Please select an Industry";
            } else {
                this.errors.industry = "";
            }

        },
        emailIdValidation() {

            if (this.ClientModel.email == undefined || this.ClientModel.email == "" || this.ClientModel.email == null) {
                this.errors.email = "Please Enter a email";
            } else {
                this.errors.email = "";

            }

        },


        validatingEmail(email) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.com$/;
            if (!regex.test(email)) {
                this.errors.email = "Please enter the Email in correct format";
                event.preventDefault();
            } else {
                this.validateEmail = true;
            }
            return this.validateEmail
        },
        clearEmail() {
            this.errors.email = "";
        },
        contactPersonValidation() {

            if (this.ClientModel.contactPerson == undefined || this.ClientModel.contactPerson == "" || this.ClientModel.contactPerson == null) {
                this.errors.contactPerson = "Please Enter a contact person";
            } else {
                this.errors.contactPerson = "";

            }

        },

        validateDevelop() {
            if(this.ClientModel.thresholdValue.develop === null || this.ClientModel.thresholdValue.develop === undefined || this.ClientModel.thresholdValue.develop === ""){
                this.errors.develop = "Enter value for Develop";
            }
            else if (this.ClientModel.thresholdValue.develop <20 || this.ClientModel.thresholdValue.develop >60 ) 
            {
                this.errors.develop = "Enter value between 20 to 60";
            }
            else
            {
                this.errors.develop = "";
                this.validateSufficient();
            }
        },

        validateSufficient() {
            if(this.ClientModel.thresholdValue.sufficient === null || this.ClientModel.thresholdValue.sufficient === undefined || this.ClientModel.thresholdValue.sufficient === ""){
                this.errors.sufficient = "Enter value for Sufficient";
            }
            else if ((this.ClientModel.thresholdValue.sufficient < this.ClientModel.thresholdValue.develop)) {
                this.errors.sufficient = "Sufficient should be greater than Develop";
            }
            else
            {
                this.errors.sufficient = "";
                this.ClientModel.thresholdValue.optimal = 100;
                this.validateOptimal();
            }

        },
        validateOptimal() {
            if(this.ClientModel.thresholdValue.optimal === null || this.ClientModel.thresholdValue.optimal === undefined || this.ClientModel.thresholdValue.optimal === ""){
                this.errors.optimal = "Please enter value for Optimal";
            }
            else if ((this.ClientModel.thresholdValue.optimal < this.ClientModel.thresholdValue.sufficient) &&(this.ClientModel.thresholdValue.optimal <this.ClientModel.thresholdValue.develop))  
            {
                this.errors.optimal = "Optimal should be greater than sufficient";
            }
            else {
                this.errors.optimal = "";
            }
        },

        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.ClientModel.clientDescription
            if (descriptionCharacterLength === undefined || descriptionCharacterLength === null || descriptionCharacterLength === "") {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
                this.ClientModel.clientDescription = this.ClientModel.clientDescription.charAt(0).toUpperCase() + this.ClientModel.clientDescription.slice(1);
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.clientDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.clientDescription = ''
                }
            })
        },


        createClientClick() {
            if (this.ClientModel.clientName == undefined || this.ClientModel.clientName == "" || this.ClientModel.clientName == null) {
                this.clientValidation();
            } else if (this.ClientModel.region == undefined) {
                this.regionValidation();
            } else if (this.ClientModel.industry == undefined) {
                this.industryValidation();
            } else if (this.ClientModel.email == undefined || this.ClientModel.email == "" || this.ClientModel.email == null) {
                this.emailIdValidation();
            } else if (this.ClientModel.contactPerson == undefined || this.ClientModel.contactPerson == "" || this.ClientModel.contactPerson == null) {
                this.contactPersonValidation();
            } else {
                this.validateDevelop();
                if(this.errors.develop === "" && this.errors.sufficient === "" && this.errors.optimal === ""){
                    this.createClientAPI();
                }
            }
            event.preventDefault();
        },

        async createClientAPI() {
            try {
                const response = await clientService.createClientAPI(
                    this.ClientModel
                );
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.createClientResponse = response.data.data;
                    this.save = true;
                    this.ClientModel.id = this.createClientResponse.id;
                    sharedService.setData(
                        "client.model",
                        this.createClientResponse
                    );
                    showToast(this.successToastMsg, 'success', 'validationsuccess')
                    this.$router.push("/clientList");
                }
                else {
                    this.save = false;
                    showToast(this.errorToastMsg, 'error', 'validationerror');
                }
            } catch (error) {
                showToast(error.response.data.message, "error", "validationerror");
                console.log('controller error log', error);
            }
        },

        async fetchClient(clientId) {
            const response = await clientService.fetchClientById(clientId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ClientModel = response.data.data;
                if(this.ClientModel && (this.ClientModel.thresholdValue === null || this.ClientModel.thresholdValue === undefined)){
                    this.ClientModel.thresholdValue= {
                        develop: '',
                        sufficient: '',
                        optimal: '',
                    }
                }  
                sharedService.setData("client.model", this.ClientModel);
            } else {
                showToast("Failed to fetch Client Details", "error", "validationerror");
            }
        },

        cancelClick() {
            this.$router.push("/clientList");
        },

    }

}