import { paginationService } from '@/app/services/pagination.service.js';
import sharedService from '@/app/services/shared.service.js';
import sortingService from '@/app/services/sort.service.js';
import statusService from '@/app/services/status.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast, toast } from '@/app/shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import clientService from '../../client/client.service.js';
import projectService from '../project.service';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import popupService from "../../shared/custompopup/popupModule.service.js";

export default {
    name: "projectList",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        ToastComponent

    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");
        return {
            toastState: toast,
            loggedInRole,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            projectList: [],
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            deleteProjectId: '',
            showEmptyTableContent: "",
            showToolTipIndex: null,
            activeTooltipType: null,
            clientId: '',
            ClientModel: {},
            sortOrder: {
                projectName: "common",
                sbu: "common",
                status: "common"

            },
            projectListResponse: [],
            searchText: '',
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            showDeleteModule: false,
            displayedProjectList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},
            projectPageFlag: false

        };
    },

    computed: {
        filteredprojectList() {
            return this.displayedProjectList;
        }

    },
    beforeUnmount() {
        if (!this.projectPageFlag) {
            this.$store.commit("removeFromHistory", this.routePath);
        }
    },
    created() {
        this.clientId = this.$route.params.clientId;
        this.fetchProjectList(this.clientId);
        this.ClientModel = sharedService.getData("client.model");
        if (!this.ClientModel) {
            this.fetchClientDetails(this.clientId);
        }
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        const midScreenMedia = window.matchMedia('((min-width:1281px) and (max-width:1440px))');
        if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "79.5%",
            }
        } 
        else if (midScreenMedia.matches) {
            this.breadcrumbStyles = {
                width: "78%",
            }
        }
        else {
            this.breadcrumbStyles = {
                width: "80.5%",
            };
        }

        
        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        isLastRow(index) {
            return index === this.filteredprojectList.length - 1 && this.filteredprojectList.length > 1;
        },
        addProjectClick() {
            this.projectPageFlag = true;
            this.$router.push({ name: "createProject", params: { clientId: this.clientId } });
        },
        editProject(project) {
            sharedService.setData("project.model", project);
            this.projectPageFlag = true;
            this.$router.push({
                name: "editProject",
                params: { projectId: project.id },
            });
        },
        async deleteProject(projectId) {
            try{
                const statusCheck = await assessmentService.fetchAssessmentByClientId(this.clientId);
            const statusResponse = statusCheck.data.data.map(item => item.assessmentStatus);
            if(statusResponse.every(status => status === 'draft' || statusCheck.data.data.length === 0)){
                popupService.togglePopup();
                popupService.togglePopupMessage("Delete Action", "This action will delete the selected project with its associated assessments, artifacts, scoring records and assigned users. Are you sure to delete?",
                (action) => {
                    if (action === 'yes') {
                        this.deleteProjectAPI(projectId);
                    }
                    else {
                        popupService.togglePopup();
                    }
                }
            );
            }else{
                    showToast('Project cannot be deleted ,assessment already started ','error','validationerror');
                }  
            }catch (err) {
                console.error('error in controller--', err);
                showToast('Failed to fetch the Project deatils', 'error', 'validationerror');
            }
        },

        async deleteProjectAPI(projectId) {
            try {
                const response = await projectService.deleteProject(projectId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.fetchProjectList(this.clientId);
                    showToast('Project Deleted Successfully', 'success', 'success');
                    popupService.togglePopup();
                    const totalPages = paginationService.viewTo(this.rowsPerPage,this.projectList);
                        if(this.selectedPage>totalPages){
                            this.selectedPage=Math.max(1,totalPages);
                        }
                        this.updateDisplayedRecords();

                } else {
                    showToast('Failed to Delete Project', 'error', 'validationerror');
                    popupService.togglePopup();
                }
            } catch (err) {
                console.error('error in controller--', err);
                showToast('Failed to Delete Project', 'error', 'validationerror');
            }
        },

        async fetchProjectList(clientId) {
            try {
                const response = await projectService.projectListByClientId(clientId)
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.projectListResponse = response.data.data;
                    this.projectList = [...this.projectListResponse];
                    if (this.projectList.length <= 0) {
                        this.showprojectListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent = "No Project to view, continue to create Project";
                    } else {
                        this.showprojectListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent = "";
                    }
                    this.updateDisplayedRecords();
                    this.projectPageFlag = false;
                } else {
                    showToast('Failed to fetch ProjectList For this Client', 'error', 'validationerror');
                }
            } catch (err) {
                showToast('Failed to fetch ProjectList For this Client', 'error', 'validationerror');
            }
        },

        searchByProjectName() {
            if (this.searchText) {
                this.displayedProjectList = this.projectList.filter(project =>
                    project.projectName.toLowerCase().includes(this.searchText.toLowerCase())
                );
                if (this.displayedProjectList.length === 0) {
                    this.showEmptyTableContent = "No projects available for searched text";
                } else {
                    this.showEmptyTableContent = "";
                }
            } else {
                this.displayedProjectList = this.projectList;
                this.showEmptyTableContent = "";

            }
        },

        getStatus(status) {
            return statusService.getStatus(status)

        },

        async fetchClientDetails(clientId) {
            const response = await clientService.fetchClientById(clientId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ClientModel = response.data.data;
                sharedService.setData("client.model", this.ClientModel);
            } else {
                showToast(
                    "Failed to fetch Client Details", "error", "validationerror");
            }
        },

        sortProject(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedProjectList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedProjectList, currentPage, rowsPerPage);
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
        },
        updateDisplayedRecords(column) {
            // use paginationservice to handle pagination logic
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.projectList
            );

            // set pagination data from the service
            this.displayedProjectList = this.paginationData.displayedRecords;

        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.projectList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },

    },
};






