const authenticate = 'api/user/authenticate'
const forgotPassword='api/user/forgotPassword?emailId='
const changePassword = 'api/user/changePassword'

//client
const fetchClient = '/api/client/fetchClient?clientId='
const createClient = '/api/client/create'
// const deleteClient = 'api/client/deleteClient?clientId'
const deleteClient = 'api/client/deleteClient?clientId='

//project
const fetchProject = '/api/project/fetchProject?projectId='
const createProject = '/api/project/create'
const deleteProject = '/api/project/deleteProject?projectId='

//frameworkadmin
const clientList = 'api/client/list'
const projectListByClientId = 'api/project/findByClient?clientId='
const assessmentListByProjectId = 'api/assessment/findAssessmentByProjectId?projectId='
const assessmentListByClientId = 'api/assessment/findAssessmentByClientId?clientId='
const assessmentListByProjectIdAndStatus = 'api/assessment/findAssessmentByProjectIdAndStatus?projectId='
const assessmentListByClientIdAndStatus = 'api/assessment/findAssessmentByClientIdAndStatus?clientId='
const findAllArtifactByAssessmentId = 'api/artifact/findArtifactByAssessment?assessmentId='

//assessor
const findAllAssessmentByUser = 'api/assessment/findAllAssessmentByUser?userId='
const findArtifactByAssessmentIdAndUserId = 'api/artifact/findArtifactByAssessmentIdAndUserId?assessmentId=' //appending userId in service file
const findAssessorsByArtifactId = 'api/userAccess/findAssessorsByArtifactId?artifactId='
const findArtifactStatusByAssessorId='api/artifact/findArtifactStatusByAssessorId?artifactId='
const findArtifactByAssessorId='api/artifact/findArtifactByAssessorId?assessorId='

//artifact score
const fetchArtifactScoringById = 'api/score/fetch?artifactId='
//const fetchArtifactScoringById = 'api/score/fetch?currentId='
const saveArtifactScore = 'api/score/save'

//assessment
const createAssessment = 'api/assessment/create'
const fetchAssessment = 'api/assessment/fetchAssessment?assessmentId='
const deleteAssessment = 'api/assessment/delete?currentId='
//artifact
const artifactListByAssessmentId = 'api/artifact/findArtifactByAssessment?assessmentId='
const updateArtifact = 'api/artifact/updateArtifact'
const saveArtifact = 'api/artifact/saveArtifact'
const deleteArtifact = 'api/artifact/delete?currentId='
const fetchArtifact = 'api/artifact/fetchArtifact?artifactId='
const updateArtifactStatus = 'api/artifact/updateArtifactStatus'
const updateOverAllArtifactStatus = 'api/artifact/updateOverAllArtifactStatus'
//user
const createUser = 'api/user/create'
const userListByAssessmentId = 'api/userAccess/findUserByAssessmentId?assessmentId='
const assignUserToArifact = 'api/userAccess/assignUser'

//update user
const updateAssignedUser = 'api/userAccess/updateAssignUser'

const deleteAssignedUser = 'api/userAccess/delete?artifactId='
//dashboard
const fetchArtifactScoreList = 'api/score/fetchArtifactScore?artifactId='

//framework
const createFramework = 'api/framework/create'
const createFrameworkRecord = 'api/framework/createRecord'
const saveAllFrameworkDetails = 'api/framework/saveAll'
const fetchFrameworkList = 'api/framework/fetchFrameworkList'
const configureFramework = 'api/framework/search?currentId='
const updateFrameworkStatus = 'api/framework/updateFrameworkStatus?frameworkId='
const deleteFramework = 'api/framework/deleteFramework?frameworkId='
const copyFramework = 'api/framework/copyFramework?frameworkId='

const deleteFrameworkRecord = 'api/framework/deleteFrameworkRecord?id='
const viewFramework = 'api/framework/search?currentId='

//user
const userList = 'api/user/list'
const refreshToken = 'api/user/refreshToken?refreshToken='
const fetchUserById = '/api/user/fetchUser?userId='
const deleteUser = '/api/user/delete?userId='


export default {
    authenticate: authenticate,
    refreshToken: refreshToken,
    changePassword:changePassword,
    forgotPassword:forgotPassword,

    //client
    fetchClient: fetchClient,
    createClient: createClient,
    deleteClient: deleteClient,

    //project
    fetchProject: fetchProject,
    createProject: createProject,
    deleteProject: deleteProject,

    //frameworkAdmin
    clientList: clientList,
    projectListByClientId: projectListByClientId,
    assessmentListByProjectId : assessmentListByProjectId,
    assessmentListByClientId : assessmentListByClientId,
    assessmentListByProjectIdAndStatus: assessmentListByProjectIdAndStatus,
    assessmentListByClientIdAndStatus: assessmentListByClientIdAndStatus,
    findAllArtifactByAssessmentId: findAllArtifactByAssessmentId,
    //assessor
    findAllAssessmentByUser: findAllAssessmentByUser,
    findArtifactByAssessmentIdAndUserId: findArtifactByAssessmentIdAndUserId,
    //score
    fetchArtifactScoringById: fetchArtifactScoringById,
    saveArtifactScore: saveArtifactScore,
    findAssessorsByArtifactId: findAssessorsByArtifactId,
    findArtifactStatusByAssessorId:findArtifactStatusByAssessorId,
    findArtifactByAssessorId:findArtifactByAssessorId,
    //assessment
    createAssessment: createAssessment,
    fetchAssessment: fetchAssessment,
    deleteAssessment: deleteAssessment,
    //artifact
    artifactListByAssessmentId: artifactListByAssessmentId,
    updateArtifact: updateArtifact,
    saveArtifact: saveArtifact,
    deleteArtifact: deleteArtifact,
    fetchArtifact: fetchArtifact,
    updateArtifactStatus: updateArtifactStatus,
    updateOverAllArtifactStatus:updateOverAllArtifactStatus,

    //user
    createUser: createUser,
    userListByAssessmentId: userListByAssessmentId,
    userList: userList,
    assignUserToArifact: assignUserToArifact,
    //update user
    updateAssignedUser: updateAssignedUser,
    deleteAssignedUser: deleteAssignedUser,
    fetchUserById: fetchUserById,
    deleteUser: deleteUser,

    //dashboard
    fetchArtifactScoreList: fetchArtifactScoreList,

    //framework
    createFramework: createFramework,
    createFrameworkRecord: createFrameworkRecord,
    configureFramework: configureFramework,
    saveAllFrameworkDetails: saveAllFrameworkDetails,
    fetchFrameworkList: fetchFrameworkList,
    updateFrameworkStatus: updateFrameworkStatus,
    deleteFramework: deleteFramework,
    copyFramework: copyFramework,
    deleteFrameworkRecord: deleteFrameworkRecord,
    viewFramework: viewFramework
}