<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="showModuleStyles">
        <div class="bg-right"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchAssessmentBox">
          <div class="d-flex justify-content-start align-items-center mb-3 me-3 breadcrumbsResponsive"
            :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mt-2 assessList150"
            style="width: 95%;">
            <div class="d-flex justify-content-between align-items-center ms-4 p-0 frameworkListResponsive"
              style="width: 92%;">
              <div class="headingTextBoxTwo d-flex align-items-center">Framework List</div>
              <div class="d-flex justify-content-between align-items-center">
                <div v-if="frameworkList.length > 0" class="searchApplication">
                  <input v-if="isSearchVisible" v-model="searchText" type="text" name="searchText" autocomplete="off"
                    placeholder="Search by Framework" class="form-control customSearch"
                    @input="searchByFrameworkName()">
                  <span @click="toggleSearch" class="customSearchIcon me-3"></span>
                </div>
                <select v-if="frameworkList.length > 0" class="page-selector ms-4" v-model="selectedView"
                  @change="viewTo" :disabled="frameworkList.length < 10">
                  <option selected disabled>view</option>
                  <option v-for="view in viewDetails" :key="view" :value="view">{{ view }}</option>
                </select>
              </div>
              <button type="search" class="btn btn-primary addNewAssessmentButton" v-on:click="createNewFramework()"
                v-if=showCreateFrameworkButton>
                Create Framework
              </button>
            </div>
            <div class="assessmentListTable mt-3 ms-4 p-0">
              <table class="searchAssessmentListTable">
                <thead>
                  <tr>
                    <th style="border-radius: 10px 0px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Framework Name</span>
                        <span
                          :class="sortOrder.name === 'ascending' ? 'icon-ascending' : sortOrder.name === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('name')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Framework Type</span>
                        <span
                          :class="sortOrder.type === 'ascending' ? 'icon-ascending' : sortOrder.type === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('type')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Client Names</span>
                        <span
                          :class="sortOrder.clientNames === 'ascending' ? 'icon-ascending' : sortOrder.clientNames === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('clientNames')"></span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Status</span>
                        <span
                          :class="sortOrder.status === 'ascending' ? 'icon-ascending' : sortOrder.status === 'descending' ? 'icon-descending' : 'icon-common'"
                          @click="sortFramework('status')"></span>
                      </div>
                    </th>
                    <th style="border-radius: 0px 10px 0px 0px;">
                      <div class="d-flex justify-content-start align-items-center">
                        <span>Action</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <div v-if="loggedInRole === 'frameworkAdmin' && showEmptyTableContent"
                    class="d-flex justify-content-center mt-5 mb-5 displayContent">
                    <span>{{ showEmptyTableContent }}</span>
                  </div>

                  <tr v-for="(framework, index) in filteredFrameworkList" :key="index">
                    <td>
                      <span class="p-0">
                        {{ framework.name }}
                      </span>
                    </td>
                    <td>
                      <span> {{ framework.type }}</span>
                    </td>
                    <td>
                      <span class="clientNames" v-if="framework.clients && framework.clients.length > 2"
                        :title="framework.clients.map(client => client.name).join(', ')">
                        {{ truncateClientNames(framework.clients.map(client => client.name).join(', ')) }}
                      </span>
                      <span v-else>{{ framework.clients.map(client => client.name).join(', ') }}</span>
                    </td>
                    <td :class="getStatus(framework.status)"><span>{{ status }}</span></td>
                    <td>
                      <div class="d-flex justify-content-start align-items-center">
                        <span type="search" class="btn d-flex justify-content-start align-items-center p-0" :class="framework.status === 'submitted' ? 'viewConfigureIcon' : 'configureIcon'"
                          v-on:click="configureFramework(framework)" @mouseover="showToolTip(index, 'configure', framework)"
                          @mouseleave="hideToolTip(index)">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'configure'" class="toolTip">
                            <div :class="['tooltiptext', isLastRow(index) ? 'up' : 'down']">
                              {{ tooltipText }}
                            </div>
                          </div>
                        </span>
                        <span type="search" class="btn editIcon d-flex justify-content-start align-items-center p-0"
                          v-on:click="editFramework(framework)" @mouseover="showToolTip(index, 'edit')"
                          @mouseleave="hideToolTip(index)">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'edit'" class="toolTip">
                            <div :class="['tooltiptext', isLastRow(index) ? 'up' : 'down']">
                              {{ tooltipText }}
                            </div>
                          </div>
                        </span>
                        <span type="search" class="btn frameworkIcon d-flex justify-content-start align-items-center p-0"
                          v-on:click="cloneFramework(framework)" @mouseover="showToolTip(index, 'clone')"
                          @mouseleave="hideToolTip(index)">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'clone'" class="toolTip">
                            <div :class="['tooltiptext', isLastRow(index) ? 'up' : 'down']">
                              Clone
                            </div>
                          </div>
                        </span>
                        <span v-if="loggedInRole === 'frameworkAdmin'" type="search"
                          class="btn deleteIcon d-flex justify-content-start align-items-center p-0"
                          v-on:click="deleteFramework(framework)" @mouseover="showToolTip(index, 'delete')"
                          @mouseleave="hideToolTip(index)">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'delete'" class="toolTip">
                            <div :class="['tooltiptext', isLastRow(index) ? 'up' : 'down']">
                              Delete
                            </div>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="frameworkList.length > 0" class="d-flex justify-content-end align-items-center mt-3 paginationBox">
            <span class="float-right pagination-detail">Jump to</span>
            <span class="me-1 ms-1 seperator"></span>
            <label class="page">Page</label>
            <div class="dropdown-wrapper">
              <select class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{ page }}</option>
              </select>
            </div>
            <span class="ms-2 me-2 seperator"></span>
            <span class="pagination-detail">
              Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{
                paginationData.totalRecords }}
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!--    /* MODALS */ -->
  <sweet-modal ref="modal" width="72%" id="frameworkSweetModal" :blocking="true">
    <div class="d-flex flex-column">
      <div class="popupHeader">
        <span>Create Framework</span>
      </div>
      <div class="popupContent">
        <div class="d-flex justify-content-end align-items-right mandatoryField">
          <span class="mandatoryFieldforForm">*&nbsp;</span> - Mandatory Fields
        </div>
        <form class="row g-3 d-flex justify-content-center align-items-center p-0">
          <div class="d-flex flex-column justify-content-center">
            <div class="row d-flex justify-content-between align-items-center p-0 popUpRowResponsive">
              <div class="col-4 d-flex flex-column">
                <span class="mandatoryFieldInput"><label class="form-label createAssessmentLabel">Framework Name</label>
                  *
                </span>
                <input type="text" autocomplete="off" id="frameworkName" name="frameworkName"
                  class="uploadArtifactFields" v-model="FrameworkModel.name" @change="frameworkNameValidation"
                  placeholder="Enter Framework name">
                <div v-if="errors.name" class="validationMessage">{{ errors.name }}</div>
              </div>
              <div class="col-4 d-flex flex-column">
                <span class="mandatoryFieldInput d-flex align-items-baseline"><label for="frameworkType"
                    class="form-label createAssessmentLabel">Framework Type</label> * </span>
                <div class="dropdownSelect" style="width:100%">
                  <select v-model="FrameworkModel.type" class="dynamicDropdown" @change="frameworkTypeValidation">
                    <option disabled selected :value="undefined" class="dropdownOption">Select the type of
                      framework</option>
                    <option v-for="framework in frameworkTypes" :key="framework.id" :value="framework" selected>
                      {{ framework }}
                    </option>
                  </select>
                  <div v-if="errors.frameworkType" class="frameworkValidationMessage">{{ errors.frameworkType }}
                  </div>
                </div>
              </div>
              <div class="col-4 d-flex flex-column">
                <span class="mandatoryFieldInput"> <label for="clientName"
                    class="form-label createAssessmentLabel">Client Name</label> * </span>
                <div class="dropdown dropdownResponsive" @click.stop>
                  <button type="button" class="dropdownbutton" @click="toggleDropdown"
                    :title="getSelectedClientNames()">
                    {{ getTruncatedClientsNames() }}
                  </button>
                  <ul v-if="showDropdown" class="dropdownMenu">
                    <li v-for="client in clientList" :key="client.id" class="dropdownOption">
                      <input type="checkbox" style="width:10%" :value="client.id" v-model="FrameworkModel.clientId"
                        @change="frameworkClientValidation" />
                      {{ client.clientName }}
                    </li>
                  </ul>
                  <div v-if="errors.clientId" class="frameworkValidationMessage">{{ errors.clientId }}</div>
                </div>
              </div>
            </div>
            <div class="row d-flex align-items-baseline mt-5 p-0 popUpRowResponsive">

              <div class="col-4 d-flex flex-column">
                <label for="description" class="form-label createAssessmentLabel">Description</label>
                <textarea v-model="FrameworkModel.description" type="text" id="description" name="description"
                  class="descriptionField" placeholder="A brief description of the Framework" maxlength="100"
                  @keypress="checkTextareaLength"></textarea>
                <div v-if="errors.frameworkDescription" class="frameworkValidationMessage">{{
                  errors.frameworkDescription }}</div>
              </div>
              <div class="col-4 d-flex flex-column">
                <label for="status" class="form-label createAssessmentLabel">Framework Status</label>
                <input :class="getStatus(FrameworkModel.status)" v-model="status" type="text" autocomplete="off" name="frameworkStatus"
                  class="uploadArtifactFields" id="frameworkStatus" readonly>
              </div>

            </div>
            <div class="d-flex justify-content-end align-items-center modalButtonResponsive">
              <button type="submit" class="btn btn-primary saveButton float-end" v-on:click="createFramework()">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </sweet-modal>
</template>
<script src="./frameworkList.controller.js"></script>
<style scoped src="./frameworkList.style.css"></style>
<style>
@media only screen and (min-width:1025px) and (max-width:1280px) {
  .sweet-modal .sweet-box-actions {
    top: 0px !important;
    right: 0px !important;
  }

  .sweet-modal .dropdownSelect::after {
    font-size: 12px !important;
  }

  #frameworkSweetModal .sweet-modal {
    width: 72% !important;
  }

  #assignUserModal .popupContent .marginTopResponsive {
    margin-left: 2rem !important;
  }

}

@media only screen and (min-width:1281px) and (max-width:1440px) {

  .popupContent .createAssessmentLabel {
    margin-left: -0.1em !important;
  }

  .sweet-modal .sweet-box-actions {
    top: 1px !important;
    right: 0px;
  }
}

/* SweetModal CSS */
.sweet-modal {
  border-radius: 10px;
}

.sweet-modal.is-alert .sweet-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.sweet-modal .sweet-content {
  padding: 0px 0px !important;
}

.sweet-modal .sweet-box-actions {
  top: 6px;
  right: 0px;
}

.sweet-modal .sweet-box-actions .sweet-action-close {
  display: block !important;
  color: #ffffff !important;
}

.sweet-modal .sweet-box-actions .sweet-action-close:hover {
  background: transparent !important;
  color: #fff !important;
}

.sweet-modal .sweet-box-actions .sweet-action-close {
  height: 35px !important;
}

#assignUserModal .sweet-modal .dropdownSelect .dynamicDropdown {
  width: 66%;
}

.sweet-modal .dropdownSelect .dynamicDropdown {
  width: 71%;
  border: none;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  font: normal normal normal 1vw/3vh Ubuntu;
}

.sweet-modal .dropdownSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sweet-modal .dropdownSelect::after {
  content: "▼";
  transform: translateY(-50%);
  font-size: 16px;
  color: #000000;
  margin-left: -1em;
  pointer-events: none;
  cursor: pointer;
}

.sweet-modal-overlay {
  z-index: 15 !important;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>