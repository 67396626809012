import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';
export default {

    async fetchUserListByAssessmentId(assessmentId) {
        return axios.get(`${apiEndpoint.userListByAssessmentId}` + assessmentId)
        .catch(function (error) {
            throw error;
       });
    },

    async assignUserToArtifact(UserAccess) {
        return  axios.post(`${apiEndpoint.assignUserToArifact}`, UserAccess)
        .catch(function (error) {
            throw error;
       });
    },

   async updateAssignedUserDetails(updateUserModel){
    return axios.post(`${apiEndpoint.updateAssignedUser}`, updateUserModel)
    .catch(function(error){
        throw error;
    });
   },

    async deleteAssignedUser(artifactId, userId, role) {
        return  axios.delete(`${apiEndpoint.deleteAssignedUser}` + artifactId + '&userId=' + userId + '&role=' + role)
        .catch(function (error) {
            throw error;
       });
    },
    async createUserAPI(user) {
        return axios.post(`${apiEndpoint.createUser}`, user)
        .catch(function (error) {
             throw error;
        }); 
    },
    async fetchUserList() {
        return axios.get(`${apiEndpoint.userList}`)
        .catch(function (error) {
            throw error;
       });
    },
    async fetchUserById(userId) {
        return axios.get(`${apiEndpoint.fetchUserById}` + userId)
        .catch(function (error) {
            throw error;
       });
    },
    async deleteUser(userId) {
        return axios.delete(`${apiEndpoint.deleteUser}` + userId)
        .catch(function (error) {
            throw error;
       });
    },

}