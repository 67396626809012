import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';
import { showToast } from '../shared/toast/toast.controller.js';
export default {

    async findAssessorsByArtifactId(artifactId) {
        return axios.get(`${apiEndpoint.findAssessorsByArtifactId}` + artifactId)
            .catch(function (error) {
                throw error;
            });
    },
    // const findUserByArtifactId = 'api/artifact/findUserByArtifactId?artifactId='

    async findArtifactStatusByAssessorId(artifactId,assessorId) {
        return axios.get(`${apiEndpoint.findArtifactStatusByAssessorId}` +
            artifactId + '&assessorId=' + assessorId)
           .catch(function(error) {
               if (error.code == 'ERR_BAD_RESPONSE') {
                   showToast('Failed to fetch the Artifact Status Please contact administrator', 'error', 'validationerror');
               } else {
                   showToast('Failed to fetch the Artifact Status ', 'error', 'validationerror');
               }
           });

    },

    async findArtifactByAssessorId(assessorId) {
        return axios.get(`${apiEndpoint.findArtifactByAssessorId}` + assessorId)
           .catch(function(error) {
               if (error.code == 'ERR_BAD_RESPONSE') {
                   showToast('Failed to fetch the Artifact Status Please contact administrator', 'error', 'validationerror');
               } else {
                   showToast('Failed to fetch the Artifact Status ', 'error', 'validationerror');
               }
           });

    },

}