<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0">
        <div class="signIn"></div>
        <div class="row mb-0 d-flex flex-column justify-content-center align-items-center createClientBox">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3" :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="headingTextBox d-flex align-items-center me-5">Create Client</div>
          <div class="whiteBox">
            <div class="d-flex flex-column justify-content-between">
              <div class="d-flex justify-content-end align-items-right mandatoryField"><span
                  class="mandatoryFieldforForm">* &nbsp;</span> - Mandatory Fields</div>
              <form class="row g-3 p-0">
                <div class="row d-flex justify-content-start align-items-center mt-2 p-0 margin150">
                  <div class="col-3">
                    <label for="clientName" class="form-label createClientLabel">Client Name</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input  v-if="ClientModel" v-model="ClientModel.clientName" name="clientName" type="text"
                        placeholder="Client Name" @change="clientValidation" />
                    </div>
                    <div v-if="errors.client" class="validationMessage">{{ errors.client }}</div>
                  </div>
                  <div class="col-3">
                    <label for="regionInput" class="form-label createClientLabel">Region</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="dropdownSelect">
                      <select v-if="ClientModel" id="region" v-model="ClientModel.region" class="dynamicDropdown"
                        @change="regionValidation">
                        <option disabled selected :value="undefined">Select a Region</option>
                        <option v-for="region in regions" :key="region" :value="region">
                          {{ region }}
                        </option>
                      </select>
                    </div>
                    <div v-if="errors.region" class="validationMessage">{{ errors.region }}</div>
                  </div>
                  <div class="col-3">
                    <label for="industryInput" class="form-label createClientLabel">Industry</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="dropdownSelect">
                      <select v-if="ClientModel" id="industry" v-model="ClientModel.industry" class="dynamicDropdown"
                        @change="industryValidation">
                        <option disabled selected :value="undefined">Select an Industry</option>
                        <option v-for="industry in industries" :key="industry" :value="industry">
                          {{ industry }}
                        </option>
                      </select>
                    </div>
                    <div v-if="errors.industry" class="validationMessage">{{ errors.industry }}</div>
                  </div>
                  <div class="col-3">
                    <label for="Email" class="form-label createClientLabel">Email ID</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input v-if="ClientModel" v-model="ClientModel.email" name="email" type="text"
                        placeholder="Email ID" @change="emailIdValidation" @blur="validatingEmail(ClientModel.email)"
                        @focus="clearEmail()" />
                    </div>
                    <div v-if="errors.email" class="validationMessage">{{ errors.email }}</div>
                  </div>
                </div>
                <div class="row d-flex justify-content-start align-items-center mt-5 p-0 margin150">
                  <div class="col-3">
                    <label for="contactPerson" class="form-label createClientLabel">Contact Person</label><span
                      class="mandatoryFieldInput"> * </span>
                    <div class="createClientDisplayField d-flex">
                      <input v-if="ClientModel" v-model="ClientModel.contactPerson" name="contactPerson" type="text"
                        placeholder="Contact Person" @change="contactPersonValidation" />
                    </div>
                    <div v-if="errors.contactPerson" class="validationMessage">{{ errors.contactPerson }}</div>
                  </div>
                  <div class="col-3">
                    <label for="status" class="form-label createClientLabel">Status</label>
                    <div class="dropdownSelect">
                      <select v-if="ClientModel" v-model="ClientModel.isActive" id="isActive" class="dynamicDropdown">
                        <option selected value="Y">Active</option>
                        <option value="N">Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <label for="clientDescription" class="form-label createClientLabel">Description</label><br>
                    <textarea v-if="ClientModel" v-model="ClientModel.clientDescription" type="text"
                      class="descriptionField" placeholder="A brief description of the Client"
                      @keypress="checkTextareaLength" maxlength="100"></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <div class="headingTextBox d-flex align-items-center me-5">Dashboard configuration - Threshold value
                <span class="mandatoryFieldInput"> * </span>
              </div>
              <div class="custom-line"></div>
              <div class="row d-flex justify-content-start align-items-center mt-2 p-0 margin150">
                <div class="col-3">
                  <label for="develop" class="form-label createClientLabel">Develop</label>
                  <div class="createClientDisplayField d-flex justify-content-between align-items-center">
                    <div v-if="ClientModel" class="d-flex">
                      <span class="thresholdrange">{{ ClientModel.thresholdValue.develop ? 0 : 0 }} -&nbsp;</span>
                      <input type="number" v-model="ClientModel.thresholdValue.develop"  :placeholder="developPlaceholder" 
                        min="0" max="60" 
                        @focus="developPlaceholder = ''" 
                        @blur="developPlaceholder = 'Develop'"  @input="validateDevelop" class="no-spinner"/>
                    </div>
                    <input type="checkbox" checked="checked" class="threshold-checkbox"  id="inputDevelop"/>
                  </div>
                  <span v-if="errors.develop" class="validationMessage">{{ errors.develop }}</span>
                </div>
                <div class="col-3">
                  <label for="sufficient" class="form-label createClientLabel">Sufficient</label>
                  <div class="createClientDisplayField d-flex justify-content-between align-items-center">
                    <div v-if="ClientModel" class="d-flex">
                      <span class="thresholdrange">{{ ClientModel.thresholdValue.develop ? ClientModel.thresholdValue.develop + 1 : '' }} -&nbsp;</span>
                      <input v-model="ClientModel.thresholdValue.sufficient" type="number" :placeholder="sufficientPlaceholder" :disabled="!ClientModel.thresholdValue.develop"
                      :min="41" max="90"  @focus="sufficientPlaceholder = ''" 
                      @blur="sufficientPlaceholder = 'Sufficient'"  @input="validateSufficient" class="no-spinner" />
                    </div>
                    <input type="checkbox" checked="checked" class="threshold-checkbox" id="inputSufficient"/>
                  </div>
                  <span v-if="errors.sufficient" class="validationMessage">{{ errors.sufficient }}</span>
                </div>
                <div class="col-3">
                  <label for="optimal" class="form-label createClientLabel">Optimal</label>
                  <div v-if="ClientModel" class="createClientDisplayField d-flex justify-content-between align-items-center">
                    <span v-if="!ClientModel.thresholdValue.sufficient">Optimal</span>
                    <span v-else class="thresholdrange">{{ ClientModel.thresholdValue.sufficient + 1 }} - 100</span>
                    <input type="checkbox" checked="checked" class="threshold-checkbox"  id="inputOptimal"/>
                  </div>
                  <span v-if="errors.optimal" class="validationMessage">{{ errors.optimal }}</span>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-end p-0 buttonsAlignmentResponsive">
              <button type="submit" class="btn btn-primary saveLaterButton float-end ms-2 "
                v-on:click="createClientClick()">
                Save
              </button>
              <input type="submit" value="Cancel" class="btn btn-primary nextButton float-end ms-2"
                style="width: 10% !important; " v-on:click="cancelClick()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createClient.controller.js"></script>
<style scoped src="./createClient.style.css"></style>