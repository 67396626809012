import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';
import { showToast } from "../shared/toast/toast.controller.js";

export default {

    async fetchClient() {
        return axios.get(`${apiEndpoint.clientList}`)
            .catch(function (error) {
                throw error;
            });
    },

    async fetchClientById(clientId) {
        return await axios.get(`${apiEndpoint.fetchClient}` + clientId)
            .catch(function (error) {
                console.log('client error', error);
            })
    },

    async fetchProjectByClientId(id) {
        return axios.get(`${apiEndpoint.projectListByClientId}` + id)
            .catch(function (error) {
                throw error;
            });
    },

    async fetchAssessmentByProjectId(projectId) {
        return axios.get(`${apiEndpoint.assessmentListByProjectId}` + projectId)
            .catch(function (error) {
                throw error;
            });
    },

    async fetchAssessmentByClientId(clientId){
        return axios.get(`${apiEndpoint.assessmentListByClientId}` + clientId)
        .catch(function(error){
            throw error;
        });
    },

    async fetchAssessmentByProjectIdAndStatus(projectId, assessmentStatus) {
        return axios.get(`${apiEndpoint.assessmentListByProjectIdAndStatus}` +
            projectId + '&assessmentStatus=' + assessmentStatus)
            .catch(function (error) {
                throw error;
            });
    },

    async fetchAssessmentByClientIdAndStatus(clientId, assessmentStatus) {
        return axios.get(`${apiEndpoint.assessmentListByClientIdAndStatus}` +
            clientId + '&assessmentStatus=' + assessmentStatus)
            .catch(function (error) {
                throw error;
            });
    },

    async fetchProjectById(projectId) {
        return await axios.get(`${apiEndpoint.fetchProject}` + projectId)
            .catch(function (error) {
                console.log('error--', error);
            })

    },


    //create Assessment
    async createAssessmentAPI(assessment) {
        return axios.post(`${apiEndpoint.createAssessment}`, assessment)
            .catch(function (error) {
                throw error;
            });
    },

    //fetchAssessment details by assessmentId
    async fetchAssessmentById(assessmentId) {
        return axios.get(`${apiEndpoint.fetchAssessment}` + assessmentId)
            .catch(function (error) {
                throw error;
            });
    },


    async fetchAssessmentByUserId(userId, role) {

        return axios.get(`${apiEndpoint.findAllAssessmentByUser}` + userId + '&role=' + role)
            .catch(function (error) {
                if (error.code == 'ERR_BAD_RESPONSE') {
                    showToast('Failed to fetch the User Assigned Assessment', 'error', 'validationerror');
                } else {
                    showToast('Failed to fetch the score ', 'error', 'validationerror');
                }
            });
    },
    async findArtifactByAssessmentIdAndUserId(id, userId) {
        return await axios.get(`${apiEndpoint.findArtifactByAssessmentIdAndUserId}` +
            id + '&userId=' + userId)
            .catch(function (error) {
                throw error;
            });
    },

    // async fetchArtifactScoring(artifactId,assessorId) {
    async fetchArtifactScoring(artifactId, assessorId) {
        return axios.get(`${apiEndpoint.fetchArtifactScoringById}` +
            artifactId + '&assessorId=' + assessorId)
            // return axios.get(`${apiEndpoint.fetchArtifactScoringById}` +
            //     currentId + '&artifactId=' + artifactId)
            .catch(function (error) {
                if (error.code == 'ERR_BAD_RESPONSE') {
                    showToast('Failed to fetch the score Please contact administrator', 'error', 'validationerror');
                } else {
                    showToast('Failed to fetch the score ', 'error', 'validationerror');
                }
            });

    },

    async saveArtifactScore(saveScoreObj) {
        return await axios.post(`${apiEndpoint.saveArtifactScore}`, saveScoreObj)
            .catch(function (error) {
                throw error;
            });

    },
    async deleteAssessment(assessmentId) {
        return await axios.delete(`${apiEndpoint.deleteAssessment}` + assessmentId)
            .catch(function (error) {
                throw error;
            });
    }
};